<template>
  <v-form class="flex-grow-1">
    <confirm ref="confirm" />
    <h1 class="mb-6">{{ headline }}</h1>
    <v-row>
      <v-col>
 <v-text-field
        outlined
        label="Unitname"
        v-model="division.name"
      ></v-text-field>
      </v-col>
     
    </v-row>
    <v-row class="mx-1 my-4">
      <h3 class="mb-2" style="width:100%">Zeitraum der Budgeterfassung</h3>
      <p>
        Gib im ersten Auswahlfeld an, ab welchem Geschäftsjahr diese Unit Budget führen wird.<br />
        Im zweiten Auswahlfeld kannst du - falls notwendig - festlegen, welches <u>das letzte Jahr</u> ist, in dem sie noch Budget führt und zur Meldung der Zahlen verpflichtet ist.
      </p>
      <p>
        Nur in Forecasts, deren Geschäftsjahr innerhalb des hier festgelegten Zeitraums liegt,
        <ul>
          <li class="mt-1">erscheint die Unit in den Budget-Übersichten und den Forecast-Meldelisten</li>
          <li>ist ein Budget-Transfer in diese Unit möglich</li>
        </ul>
      </p>
    </v-row>
    <v-row>
      <v-col>
      <v-select
        v-model="division.fy_first"
        label="Budgeterfassung ab"
        :items="fiscalYears"
        outlined
        @change="checkBoundaries('first')"
      ></v-select>
      </v-col>
      <v-col>
            <v-select
        v-model="division.fy_last"
        label="Budgeterfassung bis einschl."
        :items="fiscalYears"
        outlined
        @change="checkBoundaries('last')"
      ></v-select>
      </v-col>

    </v-row>
    <v-row class="mx-1">
      
      <v-btn
        v-if="division.id"
        depressed
        color="grey lighten-2"
        @click="softDelete"
        >Unit löschen...</v-btn
      >
     
      <v-spacer></v-spacer>
      <v-btn type="button" @click="cancel" text color="primary">{{ trl('cancel') }}</v-btn>
      <v-btn type="submit" @click.prevent="save" color="primary"
        >{{ trl('save') }}</v-btn
      >
    </v-row>
  </v-form>
</template>

<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";
import confirm from "@/components/modals/Confirm.vue";
export default {
  data() {
    return {
      headline: "Unit bearbeiten",
      division: {}
    };
  },
  computed: {
    ...mapGetters(["current", "users", "divisions","clientsWithProjects", "forecasts"]),
    fiscalYears(){
        let arr = [{value: 2020, text: '2020'}];

        for (let i = arr[0].value; i < (new Date().getFullYear()) + 3; i++) {
          arr.push({value: i, text: i.toString()})
        }
        
        arr.push({value: null, text: 'unbegrenzt'})
        return arr;
      }
  },
  methods: {
    trl,
    getDivision(id) {
      this.division = JSON.parse(
        JSON.stringify(
          this.divisions.find((d) => d.id === id) || {
            tenant_id: this.current.id,
            name: "",
            fy_first: (new Date().getMonth() < 10 ? new Date().getFullYear() : new Date().getFullYear() + 1)
          }
        )
      );

      if (!this.division.id) {
        this.headline = "Neue Unit anlegen";
      }
    },
    cancel() {
      this.$router.replace("./");
    },
    async save() {

      if (this.division.fy_first && this.division.fy_last && this.division.fy_first > this.division.fy_last) {
        this.$store.commit("alert", {title: "Ungültiger Zeitraum für die Budgeterfassung", text: "Das erste Jahr muss vor dem letzten Jahr liegen."});
        return;
      }

      

      let payload = {
        route: "division",
        data: this.division,
      };

      const res = await this.$store.dispatch("req", payload);

      await this.$store.dispatch("req", {
        route: "divisions",
        mutation: "divisions",
      });
      this.$router.replace("../");
    },
    async softDelete() {

      if (this.clientsWithProjects.filter(c => c.divisions.includes(this.division.id)).length > 0) {
        this.$store.commit("alert", {
          title: "Löschen der Unit nicht möglich", 
          text: `<strong>Die Unit "${this.division.name}" kann nicht gelöscht werden, solange sie Budget verwaltet.</strong>
                <p>Entferne in der Budgetverwaltung sämtliche Kunden und Kampagnen, indem du sie in andere Units transferierst oder löschst.</p>`
            })
        return;
      }

      let confirmed = await this.$refs.confirm.open(
        "Unit löschen",
        `<strong>Willst du die Unit "${this.division.name}" wirklich löschen?</strong>`,
        { confirmLabel: "Jetzt löschen", color: "primary", width: 500 }
      );

      if (confirmed) {
        const res = await this.$store.dispatch("req", {
          method: "DELETE",
          route: "division",
          data: this.division,
        });
        await this.$store.dispatch("req", {
          route: "divisions",
          mutation: "divisions",
        });
        this.$router.replace("../");
      }
    },
    async checkBoundaries(b){
        if (!this.division.id) return;
        let forecasts = [];
        let promises = [];
        let res = [];

        if (b === "first" && this.division.fy_first) {
            forecasts = this.forecasts.filter(f => f.fy < this.division.fy_first);
        } else if (b === "last" && this.division.fy_last) {
            forecasts = this.forecasts.filter(f => f.fy > this.division.fy_last);
        }

        if (forecasts.length === 0) return;

        forecasts.forEach(f => {
          res.push({fy: f.fy, rows: 0});
          promises.push(this.$store.dispatch("req", {
              route: "progress?tenant=" + this.division.tenant_id + "&division=" + this.division.id + "&with_live=true&fcid=" + f.id
          }));
        })

        const data = await Promise.all(promises);
        for (let i = 0; i < res.length; i++) {
          res[i].rows = data[i].length;
        }

        let sum = res.reduce((accumulator, current) => accumulator + current.rows, 0);

        if (sum === 0) return;

        this.$store.commit("alert", {
          title: "Gewählter Zeitraum nicht möglich", 
          text: `Die Unit "${this.division.name}" führt bereits Budget außerhalb des gewählten Zeitraums.
                <p>Eine Beschränkung der Budgeterfassung auf bestimmte Jahre ist nur möglich, wenn in den Jahren, die außerhalb des Zeitraums liegen, noch kein Budget erfasst wurde.</p>`
        })

    }
  },
  components: {
    confirm,
  },
  created() {
    this.getDivision(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.getDivision(to.params.id);
    next();
  },
};
</script>

<style lang="scss" scoped>




</style>